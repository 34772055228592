import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Header from '../components/header';
import Footer from '../components/Footer';
import SEO from '../components/seo';

import '../style/app.scss';

const encode = data =>
	Object.keys(data)
		.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
		.join('&');

const About = data => {
	const [submited, setSubmit] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	console.log(data);
	const image = data.data.wordpressPage.acf.profilePhoto.localFile.childImageSharp.fixed;

	function submitForm(e) {
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'contact', name, email, message }),
		})
			.then(() => setSubmit(true))
			.catch(error => alert(error));

		e.preventDefault();
	}
	return (
		<>
			<Header />
			<section className="about_hero">
				<SEO title="Andreja Mahovic" metaImage={image.src} />
				<Img
					className="about_hero_image"
					fluid={data.data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
				/>
				<div className="main_heading">
					<h1>About Me</h1>
				</div>
			</section>
			<section className="about">
				<div className="whole_wrapper">
					<div className="single_container">
						<div className="post_info_grid">
							<Img
								fluid={data.data.wordpressPage.acf.profilePhoto.localFile.childImageSharp.fluid}
								alt="Author"
							/>
							<div className="info_wrapper">
								<p>Andreja Mahovic</p>
							</div>
						</div>
						<span className="separator"></span>
						<div className="about_content">
							<div
								className="about_text"
								dangerouslySetInnerHTML={{
									__html: data.data.wordpressPage.content,
								}}
							/>
						</div>
						<span className="separator"></span>
						<div className="contact_wrapper">
							<div className="section_title" id="contact">
								<h2>Contact me :</h2>
								<span>*-required fields</span>
							</div>
							<div className="form_wrapper">
								<form
									name="contact"
									method="post"
									data-netlify="true"
									data-netlify-honeypot="bot-field"
									action=""
									onSubmit={submitForm}
								>
									<label htmlFor="form-name">
										<input type="hidden" name="form-name" value="contact" aria-label="form-name" />
									</label>
									<label htmlFor="name">
										<input
											type="text"
											placeholder="Name *"
											name="name"
											aria-label="Name"
											value={name}
											required
											onChange={e => setName(e.target.value)}
										/>
									</label>
									<label htmlFor="email">
										<input
											type="email"
											placeholder="Email *"
											aria-label="email"
											name="email"
											value={email}
											required
											onChange={e => setEmail(e.target.value)}
										/>
									</label>
									<label htmlFor="message">
										<textarea
											placeholder="Your message *"
											aria-label="message"
											name="message"
											value={message}
											required
											onChange={e => setMessage(e.target.value)}
										></textarea>
									</label>
									<label htmlFor="submit">
										<button className="submit_button" type="submit" aria-label="submit">
											SUBMIT
										</button>
										<p className={`${submited ? 'submited' : ''}`}>
											Thank you, your message is sent!
										</p>
									</label>
								</form>
							</div>
							<div className="discord_wrapper">
								<iframe
									src="https://discordapp.com/widget?id=728671168071991296&theme=dark"
									width="100%"
									height="500"
									allowTransparency="true"
									frameBorder="0"
									title="Discord Server"
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <ScrollToTop /> */}
			<Footer />
		</>
	);
};
export default About;

export const pageQuery = graphql`
	query($id: String!) {
		wordpressPage(id: { eq: $id }) {
			content
			featured_media {
				localFile {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
			acf {
				profilePhoto {
					localFile {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
							fixed {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			}
		}
	}
`;
